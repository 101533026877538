.w-md-80 {
    width: 80% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }

  
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}


.ml-auto,
.mx-auto {
  margin-left: auto !important;
}


.font-weight-normal {
    font-weight: 400 !important;
  }

  
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.4;
  color: #1e2022;
}


.text-white-70 {
    color: rgba(255, 255, 255, 0.7);
  }

  
.d-block {
    display: block !important;
  }

  
.position-absolute {
    position: absolute !important;
  }

  
.right-0 {
    right: 0;
  }

  
.bottom-0 {
    bottom: 0;
  }

  
.left-0 {
    left: 0;
  }
  
  
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}


.justify-content-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
/*------------------------------------
  Header Default
------------------------------------*/
.header {
    position: relative;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
  }
  
  .header-section {
    position: relative;
    z-index: 1;
    background-color: #fff;
  }
  
  .header-section-divider {
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
  }
  
  .header-box-shadow .header-section {
    box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
  }
  
  .header-box-shadow-on-scroll.scrolled .header-section {
    box-shadow: 0 1px 10px rgba(140, 152, 164, 0.125);
  }
  
/*------------------------------------
  Absolute Positions
------------------------------------*/
.header-abs-top {
    position: absolute;
    top: 0;
    bottom: auto;
  }
  
  @media (min-width: 576px) {
    .header-abs-top-sm {
      position: absolute;
      top: 0;
      bottom: auto;
    }
  }
  
  @media (min-width: 768px) {
    .header-abs-top-md {
      position: absolute;
      top: 0;
      bottom: auto;
    }
  }
  
  @media (min-width: 992px) {
    .header-abs-top-lg {
      position: absolute;
      top: 0;
      bottom: auto;
    }
  }
  
  @media (min-width: 1200px) {
    .header-abs-top-xl {
      position: absolute;
      top: 0;
      bottom: auto;
    }
  }
  
  @media (max-width: 767.98px) {
    .header-abs-top-md .header-abs-top-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-abs-top-md .header-abs-top-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-abs-top-md .header-abs-top-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-abs-top-md .header-abs-top-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  
  @media (max-width: 991.98px) {
    .header-abs-top .header-abs-top-inner,
    .header-abs-top-sm .header-abs-top-inner,
    .header-abs-top-lg .header-abs-top-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-abs-top .header-abs-top-inner::-webkit-scrollbar,
    .header-abs-top-sm .header-abs-top-inner::-webkit-scrollbar,
    .header-abs-top-lg .header-abs-top-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-abs-top .header-abs-top-inner::-webkit-scrollbar-thumb,
    .header-abs-top-sm .header-abs-top-inner::-webkit-scrollbar-thumb,
    .header-abs-top-lg .header-abs-top-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-abs-top .header-abs-top-inner:hover::-webkit-scrollbar-thumb,
    .header-abs-top-sm .header-abs-top-inner:hover::-webkit-scrollbar-thumb,
    .header-abs-top-lg .header-abs-top-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  
  @media (max-width: 1199.98px) {
    .header-abs-top-xl .header-abs-top-inner {
      max-height: 75vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-abs-top-xl .header-abs-top-inner::-webkit-scrollbar {
      width: 0.5rem;
    }
    .header-abs-top-xl .header-abs-top-inner::-webkit-scrollbar-thumb {
      background-color: rgba(189, 197, 209, 0.6);
      visibility: hidden;
    }
    .header-abs-top-xl .header-abs-top-inner:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }

.header-bg-transparent:not(.js-header-fix-moment) .search-push-top[style*="display: block"] + .header-section {
    background-color: #fff;
  }
  
  
.header-bg-transparent:not(.js-header-fix-moment) .header-section {
    background-color: transparent;
  }
  
  .header-bg-transparent .navbar-brand-collapsed,
  .header-bg-transparent .navbar-brand-on-scroll {
    display: none;
  }
  
  .header-bg-transparent.js-header-fix-moment .navbar-brand-default {
    display: none;
  }
  
  .header-bg-transparent.js-header-fix-moment .navbar-brand-on-scroll {
    display: inline-block;
  }
  
  @media (min-width: 576px) and (min-width: 576px) {
    .header-bg-transparent-sm:not(.js-header-fix-moment) .search-push-top[style*="display: block"] + .header-section {
      background-color: #fff;
    }
    .header-bg-transparent-sm:not(.js-header-fix-moment) .header-section {
      background-color: transparent;
    }
  }
  
  @media (min-width: 576px) {
    .header-bg-transparent-sm .navbar-brand-collapsed,
    .header-bg-transparent-sm .navbar-brand-on-scroll {
      display: none;
    }
    .header-bg-transparent-sm.js-header-fix-moment .navbar-brand-default {
      display: none;
    }
    .header-bg-transparent-sm.js-header-fix-moment .navbar-brand-on-scroll {
      display: inline-block;
    }
  }
  
  @media (min-width: 768px) and (min-width: 768px) {
    .header-bg-transparent-md:not(.js-header-fix-moment) .search-push-top[style*="display: block"] + .header-section {
      background-color: #fff;
    }
    .header-bg-transparent-md:not(.js-header-fix-moment) .header-section {
      background-color: transparent;
    }
  }
  
  @media (min-width: 768px) {
    .header-bg-transparent-md .navbar-brand-collapsed,
    .header-bg-transparent-md .navbar-brand-on-scroll {
      display: none;
    }
    .header-bg-transparent-md.js-header-fix-moment .navbar-brand-default {
      display: none;
    }
    .header-bg-transparent-md.js-header-fix-moment .navbar-brand-on-scroll {
      display: inline-block;
    }
  }
  
  @media (min-width: 992px) and (min-width: 992px) {
    .header-bg-transparent-lg:not(.js-header-fix-moment) .search-push-top[style*="display: block"] + .header-section {
      background-color: #fff;
    }
    .header-bg-transparent-lg:not(.js-header-fix-moment) .header-section {
      background-color: transparent;
    }
  }
  
  @media (min-width: 992px) {
    .header-bg-transparent-lg .navbar-brand-collapsed,
    .header-bg-transparent-lg .navbar-brand-on-scroll {
      display: none;
    }
    .header-bg-transparent-lg.js-header-fix-moment .navbar-brand-default {
      display: none;
    }
    .header-bg-transparent-lg.js-header-fix-moment .navbar-brand-on-scroll {
      display: inline-block;
    }
  }
  
  @media (min-width: 1200px) and (min-width: 1200px) {
    .header-bg-transparent-xl:not(.js-header-fix-moment) .search-push-top[style*="display: block"] + .header-section {
      background-color: #fff;
    }
    .header-bg-transparent-xl:not(.js-header-fix-moment) .header-section {
      background-color: transparent;
    }
  }
  
  @media (min-width: 1200px) {
    .header-bg-transparent-xl .navbar-brand-collapsed,
    .header-bg-transparent-xl .navbar-brand-on-scroll {
      display: none;
    }
    .header-bg-transparent-xl.js-header-fix-moment .navbar-brand-default {
      display: none;
    }
    .header-bg-transparent-xl.js-header-fix-moment .navbar-brand-on-scroll {
      display: inline-block;
    }
  }
  
  @media (max-width: 575.98px) {
    .header-bg-transparent-sm .navbar-brand-default,
    .header-bg-transparent-sm .navbar-brand-on-scroll {
      display: none;
    }
  }
  
  @media (max-width: 767.98px) {
    .header-bg-transparent-md .navbar-brand-default,
    .header-bg-transparent-md .navbar-brand-on-scroll {
      display: none;
    }
  }
  
  @media (max-width: 991.98px) {
    .header-bg-transparent-lg .navbar-brand-default,
    .header-bg-transparent-lg .navbar-brand-on-scroll {
      display: none;
    }
  }
  
  @media (max-width: 1199.98px) {
    .header-bg-transparent-xl .navbar-brand-default,
    .header-bg-transparent-xl .navbar-brand-on-scroll {
      display: none;
    }
  }
  
  .header-bg-transparent .navbar-brand-default,
  .header-bg-transparent .navbar-brand-on-scroll {
    display: none;
  }
  
  .header-bg-transparent .navbar-brand-default {
    display: -ms-flexbox;
    display: flex;
  }
  
  @media (max-width: 991.98px) {
    .header-bg-transparent .navbar-nav,
    .header-bg-transparent-sm .navbar-nav,
    .header-bg-transparent-md .navbar-nav,
    .header-bg-transparent-lg .navbar-nav {
      background-color: #fff;
      padding: 0.875rem 1.5rem;
    }
  }

  
.navbar-expand {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
  
  .navbar-expand .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  
  .navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  
  .navbar-expand .navbar-toggler {
    display: none;
  }

  
.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: #677788;
  }
  
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #0052ea;
  }
  
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-toggler {
    color: #677788;
    border-color: rgba(0, 0, 0, 0.1);
  }
  
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23677788' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  
  .navbar-light .navbar-text {
    color: #677788;
  }
  
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff;
  }
  
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff;
  }
  
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
  }
  
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .navbar-dark .navbar-text a {
    color: #fff;
  }
  
  .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: #fff;
  }
  
  
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .d-none {
    display: none !important;
  }

  
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  
.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-right: 0;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

main {
    position: relative;
  }
  

  .d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .align-items-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }

  .position-relative {
    position: relative !important;
  }
  
  
@media (min-width: 992px) {
    .vh-lg-100 {
      height: 100vh;
    }
    .min-vh-lg-100 {
      min-height: 100vh;
    }
    .min-h-lg-600rem {
      min-height: 37.5rem;
    }
  }

  
.bg-navy {
    background-color: #21325b !important;
  }
  
  a.bg-navy:hover, a.bg-navy:focus,
  button.bg-navy:hover,
  button.bg-navy:focus {
    background-color: #131d36 !important;
  }
  

  
.max-w-10rem {
    max-width: 4.5rem;
  }
  
  
.btn {
    display: inline-block;
    font-weight: 600;
    color: #677788;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 0.0625rem solid transparent;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    line-height: 1.6;
    border-radius: 0.3125rem;
    transition: all 0.2s ease-in-out;
  }
  
.btn-link {
    font-weight: 600;
  }
.text-body {
    color: #677788 !important;
  }

  
.btn-sm, .btn-group-sm > .btn {
    padding: 0.625rem 1.125rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
  

  
.img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 0.0625rem solid #e7eaf3;
    border-radius: 0.3125rem;
    max-width: 100%;
    height: auto;
  }


  
/*------------------------------------
  Navbar Expand
------------------------------------*/
.navbar-expand {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  
  .navbar-expand .navbar-navbar-toggler {
    display: none;
  }
  
  .navbar-expand .navbar-nav {
    -ms-flex-align: center;
        align-items: center;
  }
  
  .navbar-expand .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  
  .navbar-expand .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  
  .navbar-expand .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid #377dff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
  }
  
  .navbar-expand .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  
  .navbar-expand .mega-menu-position-right-fix.dropdown-menu, .navbar-expand .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  
  .navbar-expand .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  
  .navbar-expand .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  
  .navbar-expand .navbar-promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  
  .navbar-expand .navbar-promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  
  .navbar-expand .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  
  .navbar-expand .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid #e7eaf3;
    height: 100%;
    content: "";
  }
  
  @media (min-width: 576px) {
    .navbar-expand-sm {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }
    .navbar-expand-sm .navbar-navbar-toggler {
      display: none;
    }
    .navbar-expand-sm .navbar-nav {
      -ms-flex-align: center;
          align-items: center;
    }
    .navbar-expand-sm .navbar-nav .navbar-nav-last-item {
      text-align: right;
      padding-left: 1rem;
      padding-right: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      border-top: 0.1875rem solid #377dff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
      box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
      margin-top: -1.1875rem;
    }
    .navbar-expand-sm .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-sm .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
      right: 20%;
      left: auto;
    }
    .navbar-expand-sm .mega-menu-body {
      padding: 2.5rem 2.5rem;
      margin-left: 0;
      margin-right: 0;
    }
    .navbar-expand-sm .navbar-promo {
      border-bottom-right-radius: 0.3125rem;
    }
    .navbar-expand-sm .navbar-promo-card {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
      -ms-flex-direction: column;
          flex-direction: column;
    }
    .navbar-expand-sm .navbar-promo-card-deck {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
    }
    .navbar-expand-sm .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
      position: relative;
    }
    .navbar-expand-sm .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -1.5rem;
      border-right: 0.0625rem solid #e7eaf3;
      height: 100%;
      content: "";
    }
  }
  
  @media (min-width: 768px) {
    .navbar-expand-md {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }
    .navbar-expand-md .navbar-navbar-toggler {
      display: none;
    }
    .navbar-expand-md .navbar-nav {
      -ms-flex-align: center;
          align-items: center;
    }
    .navbar-expand-md .navbar-nav .navbar-nav-last-item {
      text-align: right;
      padding-left: 1rem;
      padding-right: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      border-top: 0.1875rem solid #377dff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
      box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
    }
    .navbar-expand-md .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
      margin-top: -1.1875rem;
    }
    .navbar-expand-md .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-md .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
      right: 20%;
      left: auto;
    }
    .navbar-expand-md .mega-menu-body {
      padding: 2.5rem 2.5rem;
      margin-left: 0;
      margin-right: 0;
    }
    .navbar-expand-md .navbar-promo {
      border-bottom-right-radius: 0.3125rem;
    }
    .navbar-expand-md .navbar-promo-card {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
      -ms-flex-direction: column;
          flex-direction: column;
    }
    .navbar-expand-md .navbar-promo-card-deck {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
    }
    .navbar-expand-md .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
      position: relative;
    }
    .navbar-expand-md .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -1.5rem;
      border-right: 0.0625rem solid #e7eaf3;
      height: 100%;
      content: "";
    }
  }
  
  @media (min-width: 992px) {
    .navbar-expand-lg {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }
    .navbar-expand-lg .navbar-navbar-toggler {
      display: none;
    }
    .navbar-expand-lg .navbar-nav {
      -ms-flex-align: center;
          align-items: center;
    }
    .navbar-expand-lg .navbar-nav .navbar-nav-last-item {
      text-align: right;
      padding-left: 1rem;
      padding-right: 0;
    }
   
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      border-top: 0.1875rem solid #377dff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
      box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
      margin-top: -1.1875rem;
    }
    .navbar-expand-lg .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-lg .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
      right: 20%;
      left: auto;
    }
    .navbar-expand-lg .mega-menu-body {
      padding: 2.5rem 2.5rem;
      margin-left: 0;
      margin-right: 0;
    }
    .navbar-expand-lg .navbar-promo {
      border-bottom-right-radius: 0.3125rem;
    }
    .navbar-expand-lg .navbar-promo-card {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
      -ms-flex-direction: column;
          flex-direction: column;
    }
    .navbar-expand-lg .navbar-promo-card-deck {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
    }
    .navbar-expand-lg .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
      position: relative;
    }
    .navbar-expand-lg .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -1.5rem;
      border-right: 0.0625rem solid #e7eaf3;
      height: 100%;
      content: "";
    }
  }
  
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }
    .navbar-expand-xl .navbar-navbar-toggler {
      display: none;
    }
    .navbar-expand-xl .navbar-nav {
      -ms-flex-align: center;
          align-items: center;
    }
    .navbar-expand-xl .navbar-nav .navbar-nav-last-item {
      text-align: right;
      padding-left: 1rem;
      padding-right: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      border-top: 0.1875rem solid #377dff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
      box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
      margin-top: -1.1875rem;
    }
    .navbar-expand-xl .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-xl .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
      right: 20%;
      left: auto;
    }
    .navbar-expand-xl .mega-menu-body {
      padding: 2.5rem 2.5rem;
      margin-left: 0;
      margin-right: 0;
    }
    .navbar-expand-xl .navbar-promo {
      border-bottom-right-radius: 0.3125rem;
    }
    .navbar-expand-xl .navbar-promo-card {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
      -ms-flex-direction: column;
          flex-direction: column;
    }
    .navbar-expand-xl .navbar-promo-card-deck {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
    }
    .navbar-expand-xl .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
      position: relative;
    }
    .navbar-expand-xl .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -1.5rem;
      border-right: 0.0625rem solid #e7eaf3;
      height: 100%;
      content: "";
    }
  }
  
  @media (max-width: 575.98px) {
    .navbar-expand-sm.navbar {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .navbar-expand-sm .navbar-brand,
    .navbar-expand-sm .navbar-brand > img {
      width: 7rem;
    }
    .navbar-expand-sm .navbar-nav {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .navbar-expand-sm .navbar-nav-last-item {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    .navbar-expand-sm .nav-link {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
    }
    .navbar-expand-sm .nav-link-toggle::after {
      margin-left: auto;
    }
    .navbar-expand-sm .hs-mega-menu {
      max-width: 100% !important;
    }
    .navbar-expand-sm .hs-mega-menu-opened .nav-link-toggle::after,
    .navbar-expand-sm .hs-sub-menu-opened .nav-link-toggle::after {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
    }
    .navbar-expand-sm .dropdown-item-toggle::after {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
    }
    .navbar-expand-sm .hs-sub-menu-opened > .dropdown-item-toggle::after {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
    }
    .navbar-expand-sm .mega-menu-body {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    .navbar-expand-sm .dropdown-menu {
      box-shadow: none;
    }
    .navbar-expand-sm .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
      border-left: 0.1875rem solid #e7eaf3;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .navbar-expand-sm .dropdown-menu .mega-menu-body {
      padding-left: 1.5rem;
    }
    .navbar-expand-sm .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
      margin-left: 1.5rem;
    }
    .navbar-expand-sm .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
      padding-right: 0.6125rem;
    }
    .navbar-expand-sm .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
      border-bottom: 0.0625rem solid #e7eaf3;
    }
  }
  
  @media (max-width: 767.98px) {
    .navbar-expand-md.navbar {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .navbar-expand-md .navbar-brand,
    .navbar-expand-md .navbar-brand > img {
      width: 7rem;
    }
    .navbar-expand-md .navbar-nav {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .navbar-expand-md .navbar-nav-last-item {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    .navbar-expand-md .nav-link {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
    }
    .navbar-expand-md .nav-link-toggle::after {
      margin-left: auto;
    }
    .navbar-expand-md .hs-mega-menu {
      max-width: 100% !important;
    }
    .navbar-expand-md .hs-mega-menu-opened .nav-link-toggle::after,
    .navbar-expand-md .hs-sub-menu-opened .nav-link-toggle::after {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
    }
    .navbar-expand-md .dropdown-item-toggle::after {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
    }
    .navbar-expand-md .hs-sub-menu-opened > .dropdown-item-toggle::after {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
    }
    .navbar-expand-md .mega-menu-body {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    .navbar-expand-md .dropdown-menu {
      box-shadow: none;
    }
    .navbar-expand-md .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
      border-left: 0.1875rem solid #e7eaf3;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .navbar-expand-md .dropdown-menu .mega-menu-body {
      padding-left: 1.5rem;
    }
    .navbar-expand-md .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
      margin-left: 1.5rem;
    }
    .navbar-expand-md .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
      padding-right: 0.6125rem;
    }
    .navbar-expand-md .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
      border-bottom: 0.0625rem solid #e7eaf3;
    }
  }
  
  @media (max-width: 991.98px) {
    .navbar-expand-lg.navbar {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .navbar-expand-lg .navbar-brand,
    .navbar-expand-lg .navbar-brand > img {
      width: 7rem;
    }
    .navbar-expand-lg .navbar-nav {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .navbar-expand-lg .navbar-nav-last-item {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    .navbar-expand-lg .nav-link {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
    }
    .navbar-expand-lg .nav-link-toggle::after {
      margin-left: auto;
    }
    .navbar-expand-lg .hs-mega-menu {
      max-width: 100% !important;
    }
    .navbar-expand-lg .hs-mega-menu-opened .nav-link-toggle::after,
    .navbar-expand-lg .hs-sub-menu-opened .nav-link-toggle::after {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
    }
    .navbar-expand-lg .dropdown-item-toggle::after {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
    }
    .navbar-expand-lg .hs-sub-menu-opened > .dropdown-item-toggle::after {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
    }
    .navbar-expand-lg .mega-menu-body {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    .navbar-expand-lg .dropdown-menu {
      box-shadow: none;
    }
    .navbar-expand-lg .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
      border-left: 0.1875rem solid #e7eaf3;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .navbar-expand-lg .dropdown-menu .mega-menu-body {
      padding-left: 1.5rem;
    }
    .navbar-expand-lg .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
      margin-left: 1.5rem;
    }
    .navbar-expand-lg .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
      padding-right: 0.6125rem;
    }
    .navbar-expand-lg .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
      border-bottom: 0.0625rem solid #e7eaf3;
    }
  }
  
  @media (max-width: 1199.98px) {
    .navbar-expand-xl.navbar {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .navbar-expand-xl .navbar-brand,
    .navbar-expand-xl .navbar-brand > img {
      width: 7rem;
    }
    .navbar-expand-xl .navbar-nav {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .navbar-expand-xl .navbar-nav-last-item {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    .navbar-expand-xl .nav-link {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
    }
    .navbar-expand-xl .nav-link-toggle::after {
      margin-left: auto;
    }
    .navbar-expand-xl .hs-mega-menu {
      max-width: 100% !important;
    }
    .navbar-expand-xl .hs-mega-menu-opened .nav-link-toggle::after,
    .navbar-expand-xl .hs-sub-menu-opened .nav-link-toggle::after {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
    }
    .navbar-expand-xl .dropdown-item-toggle::after {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
    }
    .navbar-expand-xl .hs-sub-menu-opened > .dropdown-item-toggle::after {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
    }
    .navbar-expand-xl .mega-menu-body {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    .navbar-expand-xl .dropdown-menu {
      box-shadow: none;
    }
    .navbar-expand-xl .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
      border-left: 0.1875rem solid #e7eaf3;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .navbar-expand-xl .dropdown-menu .mega-menu-body {
      padding-left: 1.5rem;
    }
    .navbar-expand-xl .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
      margin-left: 1.5rem;
    }
    .navbar-expand-xl .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
      padding-right: 0.6125rem;
    }
    .navbar-expand-xl .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
      border-bottom: 0.0625rem solid #e7eaf3;
    }
  }
  
  .navbar-expand.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .navbar-expand .navbar-brand,
  .navbar-expand .navbar-brand > img {
    width: 7rem;
  }
  
  .navbar-expand .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .navbar-expand .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  
  .navbar-expand .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  
  .navbar-expand .nav-link-toggle::after {
    margin-left: auto;
  }
  
  .navbar-expand .hs-mega-menu {
    max-width: 100% !important;
  }
  
  .navbar-expand .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  
  .navbar-expand .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  
  .navbar-expand .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  
  .navbar-expand .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  
  .navbar-expand .dropdown-menu {
    box-shadow: none;
  }
  
  .navbar-expand .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid #e7eaf3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .navbar-expand .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  
  .navbar-expand .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  
  .navbar-expand .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  
  .navbar-expand .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
  
  @media (max-width: 767.98px) {
    .navbar-expand-sm .header-nav-last-item {
      display: none;
    }
  }
  
  @media (max-width: 991.98px) {
    .navbar-expand-xs .navbar-banner,
    .navbar-expand-xs .navbar-product-banner,
    .navbar-expand-sm .navbar-banner,
    .navbar-expand-sm .navbar-product-banner,
    .navbar-expand-md .navbar-banner,
    .navbar-expand-md .navbar-product-banner,
    .navbar-expand-lg .navbar-banner,
    .navbar-expand-lg .navbar-product-banner {
      display: none;
    }
    .navbar-expand .navbar-brand {
      padding-top: 0.5rem;
    }
  }
  
  @media (max-width: 1199.98px) {
    .navbar-expand-xl .navbar-banner,
    .navbar-expand-xl .navbar-product-banner {
      display: none;
    }
  }
  
  @media (min-width: 1200px) {
    .header .mega-menu-position-right-fix-xl.hs-mega-menu.hs-position-right {
      right: 20%;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .navbar-expand-md .navbar-brand {
      padding-top: 0.5rem;
    }
    .header .mega-menu-position-right-fix-md.hs-mega-menu.hs-position-right {
      right: 20%;
    }
  }
  
  @media (min-width: 992px) {
    [class*="navbar-expand"]:not(.navbar-expand-xl) {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
    }
    .navbar-expand-lg-collapse-block .navbar-collapse {
      display: block !important;
    }
  }

  @media (min-width: 481px){ .auth0-lock.auth0-lock .auth0-lock-header-bg { background: green; } }

  .auth0-lock-header-bg {display: none; }