// @import '~@immersion/react-component/dist/theme/css/global';
.ant-form-item-control{line-height:normal !important;}
.form-control-integrated{
  .ant-form label{font-size:13px;}
  .ant-input{
    height: 49px !important;
  }  
  .ant-picker {
    height: 49px;
  }

  // sp
  .ant-select-selector {
    min-height: 49px !important;
  }

  // sp
  // .ant-select-selection-item{
  //   padding-top: 10px;
  // }

  .ant-select-single.ant-select-show-arrow
   .ant-select-selection-item, 
   .ant-select-single
   .ant-select-show-arrow 
   .ant-select-selection-placeholder{
    padding-top: 7px;    
  }


  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 40px;  
  }

  .ant-select-selection--single{    
    .ant-select-selection__placeholder{      
      padding-right:5px;
      padding-top: 10px;
    }
  }
  .ant-input-group{
    .ant-input-group-addon{
      .anticon{
        svg{
          margin:0;
        }
      }
    }
  }
  .ant-dropdown-button {
    margin: 0 8px 8px 0;
  }
  .ant-btn{
    &.ant-dropdown-trigger{
      height:48px;
      > .anticon{
        vertical-align: top;
      }
    }
  }
  .ant-input-group-addon{
    padding: 0 17px;
    background-color: transparent;
    span.fa{
      color:#8c98a4;
    }
  }

  .ant-input-group-wrapper-lg{
    .ant-input-group-addon{
      padding:0 17px;
    }
  }

  .ant-calendar-picker{
    input{padding-left:36px;}
    .ant-calendar-picker-clear, & .ant-calendar-picker-icon{
      right: auto;
      left:12px;
    }
  }
  .ant-form-explain{
    margin-top:2px;
    font-size:11px;
  }
  .form-label{
    font-size:12px;
  }
}

.form-group-label{
  color:#8c98a4;
  font-size:18px;
  font-weight: 500;
  padding-bottom: 15px;
}

.ant-drawer{
  z-index:1002;
}

.ant-modal-content{
  .ant-modal-close-x{
    i{
      position: relative;
      &:after{
        position:absolute;
        right:-4px;
        top:-4px;
        font-family: "Font Awesome 5 Free";
        content: '\f00d';
        color:#707070;
      }
      svg{
        display: none;
      }
    }
  }
  .ant-modal-header{
    padding:20px 31px;
    background:#F8F9FA;
    border-bottom: none;
    .ant-modal-title{
      font-weight: 500;
    }
  }
}

.table-integrated{
  .ant-table-thead > tr {
    background: none;
  }
  .ant-table-column-has-sorters{
    .ant-table-header-column{
      display:block;
      .ant-table-column-sorters{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .ant-pagination-disabled{
    display:none;
  }
}

.separated-radio-group {
  .ant-radio-button-wrapper{
    border-radius:4px;
    margin:0 5px;
    border:0;
    padding:0 30px;
    font-size:12px;
    background:#F8F9FA;
    &::before{
      display:none;
    }
    &:first-child{
      border-radius:4px;
      margin-left:0;
    }
    &:last-child{
      border-radius:4px;
      margin-right:0;
    }
  }
}

.listpage-head{
  display: flex;
  align-items: center;
  margin-bottom:10px;
  h1{
    font-size:15px;
    font-weight: 500;
    width:100%;
  }
  h3{
    width: 100%;
    font-size: 24px;
  }
}

.tag-select-list-style{
  .ant-select-selection__rendered{
    margin-right: 5px;
    ul{
      li:not(:last-child){
        float: none;
        margin-right:0;
        max-width: 100%;
        height:38px;
        .ant-select-selection__choice__content{
          padding-left:10px;
          font-size:12px;
        }
        .ant-select-selection__choice__remove{
          right: 3px;
        }
      }
    }
  }
}

.custom-table{
  table{
    font-size:12px;
    tbody{
      tr:nth-child(2n-1){
        background:#F8FAFD;
      }
    }
    thead{
      tr{
        background:transparent;
      }
    }
  }
}

.ant-empty-image svg {
  height: 100%;
}

// success btn
.ant-btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-success-disabled,
.ant-btn-success.disabled,
.ant-btn-success[disabled],
.ant-btn-success-disabled:hover,
.ant-btn-success.disabled:hover,
.ant-btn-success[disabled]:hover,
.ant-btn-success-disabled:focus,
.ant-btn-success.disabled:focus,
.ant-btn-success[disabled]:focus,
.ant-btn-success-disabled:active,
.ant-btn-success.disabled:active,
.ant-btn-success[disabled]:active,
.ant-btn-success-disabled.active,
.ant-btn-success.disabled.active,
.ant-btn-success[disabled].active {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff !important;
  background-color: #34ce57 !important;
  border-color: #34ce57 !important;
}
.ant-btn-success:active,
.ant-btn-success.active {
  color: #fff !important;
  background-color: #1e7e34 !important;
  border-color: #1e7e34 !important;
}
.ant-btn-background-ghost.ant-btn-success {
  color: #28a745 !important;
  background: transparent !important;
  border-color: #28a745 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-success:hover,
.ant-btn-background-ghost.ant-btn-success:focus {
  color: #34ce57 !important;
  background: transparent !important;
  border-color: #34ce57 !important;
}
.ant-btn-background-ghost.ant-btn-success:active,
.ant-btn-background-ghost.ant-btn-success.active {
  color: #28a745 !important;
  background: transparent !important;
  border-color: #1e7e34 !important;
}
.ant-btn-warning {
  color: #fff !important;
  background-color: #eca52b !important;
  border-color: #eca52b !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-warning-disabled,
.ant-btn-warning.disabled,
.ant-btn-warning[disabled],
.ant-btn-warning-disabled:hover,
.ant-btn-warning.disabled:hover,
.ant-btn-warning[disabled]:hover,
.ant-btn-warning-disabled:focus,
.ant-btn-warning.disabled:focus,
.ant-btn-warning[disabled]:focus,
.ant-btn-warning-disabled:active,
.ant-btn-warning.disabled:active,
.ant-btn-warning[disabled]:active,
.ant-btn-warning-disabled.active,
.ant-btn-warning.disabled.active,
.ant-btn-warning[disabled].active {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #fff !important;
  background-color: #f0b95a !important;
  border-color: #f0b95a !important;
}
.ant-btn-warning:active,
.ant-btn-warning.active {
  color: #fff !important;
  background-color: #d18b13 !important;
  border-color: #d18b13 !important;
}
.ant-btn-background-ghost.ant-btn-warning {
  color: #eca52b !important;
  background: transparent !important;
  border-color: #eca52b !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-warning:hover,
.ant-btn-background-ghost.ant-btn-warning:focus {
  color: #f0b95a !important;
  background: transparent !important;
  border-color: #f0b95a !important;
}
.ant-btn-background-ghost.ant-btn-warning:active,
.ant-btn-background-ghost.ant-btn-warning.active {
  color: #eca52b !important;
  background: transparent !important;
  border-color: #d18b13 !important;
}
.ant-btn-info {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-info-disabled,
.ant-btn-info.disabled,
.ant-btn-info[disabled],
.ant-btn-info-disabled:hover,
.ant-btn-info.disabled:hover,
.ant-btn-info[disabled]:hover,
.ant-btn-info-disabled:focus,
.ant-btn-info.disabled:focus,
.ant-btn-info[disabled]:focus,
.ant-btn-info-disabled:active,
.ant-btn-info.disabled:active,
.ant-btn-info[disabled]:active,
.ant-btn-info-disabled.active,
.ant-btn-info.disabled.active,
.ant-btn-info[disabled].active {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-info:hover,
.ant-btn-info:focus {
  color: #fff !important;
  background-color: #1fc8e3 !important;
  border-color: #1fc8e3 !important;
}
.ant-btn-info:active,
.ant-btn-info.active {
  color: #fff !important;
  background-color: #117a8b !important;
  border-color: #117a8b !important;
}
.ant-btn-background-ghost.ant-btn-info {
  color: #17a2b8 !important;
  background: transparent !important;
  border-color: #17a2b8 !important;
  text-shadow: none !important;
}
.ant-btn-background-ghost.ant-btn-info:hover,
.ant-btn-background-ghost.ant-btn-info:focus {
  color: #1fc8e3 !important;
  background: transparent !important;
  border-color: #1fc8e3 !important;
}
.ant-btn-background-ghost.ant-btn-info:active,
.ant-btn-background-ghost.ant-btn-info.active {
  color: #17a2b8 !important;
  background: transparent !important;
  border-color: #117a8b !important;
}