.template-header{
  background:#f7f7f7;
}


.logo-container {
  display: flex;
  .logo-img {
  max-height: 52px;
  margin: auto;
  max-width: 300px;
  height: auto;
  width: auto;
}
}